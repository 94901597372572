<template>

  <div class="Detail">
    <main-nav />

    <div class="tabbarTar">
      <div class="bannerTabbar">
        <img src="../../../assets/supplyBanner.png" alt="" />
      </div>
    </div>
    <div class="wrapper" ref="wrapper">
      <div class="contentFrame">
        <div class="title">热烈欢迎田秀英女士成为广东省湖南怀化商会会员</div>
        <div class="contentImg">
          <img
            src="../../../assets/supplyDemand/supplyOne-02.jpg"
            alt=""
          />
        </div>
        <div class="text-center">田秀英</div>
        <div class="text-center">广东省湖南怀化商会-个人会员</div>
        <div class="text-center">祖籍：怀化中方</div>
        <div class="contentImg-bor">
          <img
            src="../../../assets/supplyDemand/supplyOne-01.jpg"
            alt=""
          />
        </div>
        <div class="borderC">
          <div class="personal-resume">
            <div class="resumeTitle">个人简介</div>
            <div class="content">
              <span style="color: #fff;">女士</span>田秀英女士，湖南怀化中方人，在深圳20多年，98年做餐饮管理，开日本料理，在香港注册贸易公司，一直代理法国 智利 进口原装原瓶进口葡萄酒 ，也是进口商，都是一手货源(一直都有经营)
            </div>
            <div class="content">
              &#8195;
              &#8195;2015年 把香港贸易公司扩大与潮州家族进口贸易商 入股合作
            </div>
            <div class="content">
            </div>
            <div class="content">
              合作产品如下：
            </div>
            <div class="content">
              <span style="color: #fff;">产品</span>♻️品牌：10个国际品牌，4个过百年历史，品类有1000种 品种
            </div>
            <div class="content">
              <span style="color: #fff;">产品</span>♻️主要经营市场：母婴市场，进口中成药市场，酒水市场，SPA馆美业市场！
            </div>
            <div>
              <div class="content">
                战略联盟合作商:
              </div>
              <div class="content">
                <span style="color: #fff;">战略</span>1、日本大同药业台湾大江生医
              </div>
              <div class="content">
                <span style="color: #fff;">战略</span>2、王子药业，蓬兴生物科技
              </div>
              <div class="content">
                <span style="color: #fff;">战略</span>3、法国90年历史蒙太居的奶粉工厂
              </div>
              <div class="content">
                <span style="color: #fff;">战略</span>4、法国波尔多 多家历史酒庄
              </div>
              <div class="content">
                ♻️产品特色：国际认证品质保证，独家代理，独有的国际产品专利，   趋势性产品，一手货源，没有中间商，翻单快，不压货，没有三角债，市场需求大，不需要太多后续服务，轻资产！
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <back-to-top :custom-style="myBackToTopStyle" :visibility-height="300" :back-position="0" transition-name="fade" />

    <Footer />
  </div>
</template>
<script>
import Footer from "../../../components/Footer.vue";
import MainNav from "../../../components/MainNav/index";
export default {
  data() {
    return {
      isNews1: null,
      myBackToTopStyle: {
        right: '50px',
        bottom: '50px',
        width: '6rem',
        height: '6rem',
        'border-radius': '50%',
        'line-height': '45px', // 请保持与高度一致以垂直居中
      },
    };
  },
  components: {
    Footer,
    MainNav
  },
  mounted () {
    this.toPinglun()
  },
  methods: {
    // 锚点跳到指定位置
    toPinglun () {
      this.$refs['wrapper'].scrollIntoView(true)
    },
    toCompany () {
      this.$router.push({
        name: "members",
      });
    },
    toNews () {
      this.$router.push({
        name: "members",
        params: { newType: 1 },
      });
    },
    toNext() {
      this.$router.push({
        name: "supply02",
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../styles/news";
.Detail {
  font-size: 12px;

  .tabbarTar {
    // height: 52rem;
    position: relative;
    margin-bottom: 2.17%;
    .bannerTabbar {
      // height: 52rem;
      width: 100%;
      img {
        width: 100%;
        // height: 52rem;
      }
    }
  }
  .wrapper {
    width: 104rem;
    text-align: center;
    margin: 5% auto 0;
    .contentFrame {
      .title {
        font-size: 3.17rem;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 4.42rem;
        margin-bottom: 4rem;
      }
      .title2 {
        font-size: 2rem;
        text-align: center;
      }
      .text-center {
        font-size: 30px;
        line-height: 6.2rem;
        font-weight: 600;
      }
      .content {
        font-size: 1.6rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin-bottom: 2.33rem;
      }
      .contentImg {
        text-align: center;
        img {
          width: 50rem;
          margin-bottom: 2.33rem;
        }
      }
      .contentImg-bor {
        text-align: center;
        img {
          width: 90.8rem;
          margin-bottom: 2.33rem;
        }
      }
      // 个人简历
      .borderC {
        border: 3px solid #8d8d8d;
        margin: 3rem 0;
        padding: 1rem;
        .personal-resume {
          // width: 80%;
          border: 6px dotted #8d8d8d;
          padding: 2rem;
          text-align: left;
          .resumeTitle {
            text-align: center;
            font-size: 3rem;
            margin: 3rem 0;
          }
        }
      }
    }
    .changeBtn {
      margin-top: 4.33rem;
      padding-bottom: 10rem;
      font-size: 1.33rem;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      .previous {
        display: inline-block;
        width: 28rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        span {
          cursor: pointer;
        }
        span:hover {
          color: #ff6700;
        }
      }
      .next {
        float: right;
        width: 28rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        span {
          cursor: pointer;
        }
        span:hover {
          color: #ff6700;
        }
      }
    }
  }
}
</style>